class OpalThemeStickyHeader {
    constructor() {
        //this.sticky();
    }

    sticky() {
        let $scope = $('#opal-header-content');
        if ($scope.length > 0) {
            let section_offset = $scope.offset().top,
                section_top    = $('#wpadminbar').height(),
                section_height = $scope.outerHeight();
            if ($(window).width() < 601) {
                section_top = 0;
            }
            $(window).resize(() => {
                $scope.removeClass('sticky-show');
                $scope.css({
                    top: 0,
                });
                let $h_animate = $scope.next('.h-animate');
                $h_animate.remove();
                if (!$scope.hasClass('sticky-show')) {
                    section_offset = $scope.offset().top;
                }
                section_top = $('#wpadminbar').height();
                if ($(window).width() < 601) {
                    section_top = 0;
                }
            });
            $(window).scroll(() => {
                if ($(window).width() > 768) {
                    if ($(window).scrollTop() >= (section_offset + section_height - section_top)) {
                        if (!$scope.hasClass('sticky-show')) {
                            $scope.addClass('sticky-show');
                            $scope.css({
                                top: section_top,
                            });
                            $("<div class='h-animate'></div>").insertAfter($scope).css({
                                height: section_height
                            });
                        }
                    } else {
                        $scope.removeClass('sticky-show');
                        $scope.css({
                            top: 0,
                        });
                        let $h_animate = $scope.next('.h-animate');
                        $h_animate.remove();
                    }
                }
            });
        }
    }
}

jQuery(document).ready(function ($) {
    new OpalThemeStickyHeader();
});