class OpalThemeToogle {
    constructor() {
        this.setupHeader();
        this.toggleCollapse();
        this.ToggleCanvasFilter();
        this.PositionAccount();
        this.toggleCollapseSearchFooter();
        this.toggleCollapseWoo();
    }

    setupHeader() {
        jQuery('#masthead .search-button[data-search-toggle]').each((index, element) => {
            let $button = $(element);
            if ($button.hasClass('top-to-bottom') || $button.hasClass('popup')) {
                let $searchform = $($button.data('target'));
                $searchform.data('height', $searchform.outerHeight()).prependTo('#page').addClass('loaded');
            } else if ($button.hasClass('bottom-to-top')) {
                let $searchform = $($button.data('target'));
                $searchform.data('height', $searchform.outerHeight()).prependTo('#page');
            }
        });
    }

    toggleCollapse() {
        jQuery('body').on('click', '[data-search-toggle="toggle"]', (e) => {
            e.preventDefault();
            let $button = $(e.currentTarget);
            let $searchForm = $($button.data('target'));
            let $buttonClose = $searchForm.find('[data-search-toggle="close"]');
            $buttonClose.on('click', (e) => {
                $button.removeClass('active');
                $searchForm.removeClass('active');
                jQuery('body').removeClass('over-hidden');
                if ($buttonClose.closest('.bottom-to-top')) {
                    $searchForm.css('top', '100%');
                }
            });
            if ($button.is('.top-to-bottom, .bottom-to-top, .popup')) {
                $searchForm.toggleClass('active');
                $button.toggleClass('active');
            }
            if ($button.hasClass('top-to-bottom')) {
                this.setupTopToBottom($button, $searchForm);
            } else if ($button.hasClass('bottom-to-top')) {
                this.setupBottomToTop($button, $searchForm);
            } else if ($button.hasClass('popup')) {
                this.setupFullScreen($button, $searchForm);
            } else {
                $button.toggleClass('active');
                $button.siblings($searchForm).toggleClass('active');
                $(document.createElement('div'))
                    .addClass('dropdown-backdrop')
                    .insertAfter($button.siblings($searchForm))
                    .on('click', function () {
                        jQuery(this).siblings().removeClass("active");
                        jQuery(this).remove();
                    });
            }
        });
    }

    toggleCollapseWoo() {
        jQuery('body').on('click', '.woocommerce-message, .woocommerce-info, .woocommerce-error, .woocommerce-notice', function () {
            $(this).addClass('hidden-notice');
        });
        jQuery('.home .woocommerce-message, .home .woocommerce-info, .home .woocommerce-error').detach().insertAfter('.opal-wrapper');
        jQuery('.track_order').prev('.woocommerce-message, .woocommerce-info, .woocommerce-error, .woocommerce-notice').detach().insertAfter('.opal-wrapper');

    }

    toggleCollapseSearchFooter() {
        jQuery('body').on('click', '.search-footer', (e) => {
            e.preventDefault();
            $('.handheld-footer-bar .search').toggleClass('active');
        });
    }

    checkScroll($object) {
        let element = $object.get(0);
        return element.scrollHeight > $(window).height();
    }

    setupFullScreen($button, $searchForm) {
        let $body = jQuery('body');
        if (this.checkScroll($body)) {
            $body.toggleClass('over-hidden');
        }
    }

    setupTopToBottom($button, $searchForm) {
        jQuery('body').animate({scrollTop: 0}, 600);
    }

    setupBottomToTop($button, $searchForm) {
        let $header = jQuery('.site-header'),
            $sticky = jQuery('.sticky-show'),
            stickyHeight = $sticky.outerHeight(),
            adminbarHeight = jQuery('#wpadminbar').height(),
            position = $header.height() + adminbarHeight;
        if (stickyHeight > 1) {
            position = stickyHeight + adminbarHeight;
        }
        if ($searchForm.hasClass('active')) {
            $searchForm.css('top', position);
        } else {
            $searchForm.css('top', '100%');
        }
        let $body = $('body');
        if (this.checkScroll($body)) {
            $body.toggleClass('over-hidden');
        }
    }

    ToggleCanvasFilter() {
        jQuery('body').on('click', '.opal-overlay-filter , .filter-close', function () {
            jQuery('body').removeClass('opal-canvas-filter-open');
            jQuery('.filter-toggle').removeClass('active');
        }).on('click', '.filter-toggle', function (event) {
            let $body = jQuery('body');
            let filterToggle = $(event.currentTarget);
            if ($body.hasClass('opal-canvas-filter-open')) {
                filterToggle.removeClass('active');
                $body.removeClass('opal-canvas-filter-open');
                $body.find('.opal-canvas-filter.top').slideToggle(1000);
            } else {
                filterToggle.addClass('active');
                $body.addClass('opal-canvas-filter-open');
                $body.find('.opal-canvas-filter.top').slideToggle(1000);
            }
        });

    }

    PositionAccount() {
        jQuery('body').on('hover', '.site-header-account', function () {
            let $drop = jQuery(this).find('.account-dropdown');
            if ($drop.length > 0) {
                let position = ($drop.offset().top - $(window).scrollTop()) + $drop.outerHeight(true),
                    top = position - jQuery(window).height();
                if (top > 0) {
                    $drop.css({
                        'transform': 'translateY( -' + top + 'px)'
                    });
                }
            }
        });
    }

}

jQuery(document).ready(function ($) {
    new OpalThemeToogle();
});