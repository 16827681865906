class OpalThemeLogin {
    constructor() {
        jQuery('body').on('click', '.opal-login-form-ajax button[type="submit"]', (event) => {
            let $this = $(event.currentTarget);
            let $form = $this.closest('form');

            $.ajax({
                type      : 'POST',
                url       : ajaxurl,
                data      : $form.serialize(),
                beforeSend: () => {
                    $form.addClass('loading');
                    $form.find('.woocommerce-error').remove();
                    $form.find('input,button').prop('disabled', true);
                },
                success   : (response) => {
                    if (response.status) {
                        location.reload();
                    } else {
                        $form.before(`<div class="woocommerce-error">${response.msg}</div>`);
                    }
                },
                complete  : function () {
                    $form.find('input,button').prop('disabled', false);
                    $form.removeClass('loading');
                }
            });

            return false;
        });
    }

    beforeSend() {

    }


}

new OpalThemeLogin();