class OpalTheme {
    constructor() {
        this.SetUpBreacumb();
        jQuery(window).resize(() => {
            this.SetUpBreacumb();
        });
        this.add_text();
    }

    SetUpBreacumb() {

        let header = jQuery('body.opal-header-absolute #masthead .site-header'),
            breacumb = jQuery('.page-title-bar .wrap');
        if (header.length) {
            let Height = header.height();

            if (jQuery(window).width() > 992) {
                breacumb.css({
                    'min-height': 210 + Height,
                    'padding-top': Height
                });
            } else {
                breacumb.css({
                    'min-height': '',
                    'padding-top': ''
                });
            }
        }
    }

    add_text(){
        jQuery(".widget-title").html(function(){
            var text= jQuery(this).text().trim().split(" ");
            var first = text.shift();
            return (text.length > 0 ? "<span class='first'>"+ first + "</span> " : first) + text.join(" ");
        });
    }


}

jQuery(document).ready(function ($) {
    new OpalTheme();
});