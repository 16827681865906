class OpalThemeSmoothMenu {
    constructor() {
        huntorJS.smoothCallback = function(selector){
            jQuery('.opal-smooth-menu a[href^="'+selector+'"]').trigger('click');
        }

        jQuery('body').on('click', '.opal-smooth-menu a[href^="#"]',  (e) => {
            e.preventDefault();
            this.menuActiveClass(e);
            let target = e.currentTarget.hash;
            let $target = $(target);
            if($target.length > 0){
                jQuery('html, body').animate({
                    'scrollTop': $target.offset().top - this.getOffset()
                }, 600, 'swing');
            }
        });
    }


    menuActiveClass(e){
        let $this = $(e.currentTarget);
        jQuery('.opal-smooth-menu').find('li.menu-item').removeClass('current-menu-item current_page_item');
        let selector = $this.closest('li').attr('id');
        jQuery('.opal-smooth-menu [id="'+ selector +'"]').addClass('current-menu-item');
    }

    getOffset(){
        let offset = 0;
        let $adminBar = jQuery('#wpadminbar');
        let $stickyHeader = jQuery('#opal-header-sticky');

        if($adminBar.length > 0){
            offset += $adminBar.outerHeight();
        }

        if($stickyHeader.length > 0){
            offset += $stickyHeader.outerHeight();
        }

        return offset;
    }
}


new OpalThemeSmoothMenu();