function opalAddQuantityBoxes($quantitySelector = '.qty') {

    let $quantityBoxes;

    $quantityBoxes = jQuery('div.quantity:not(.buttons_added), td.quantity:not(.buttons_added)').find($quantitySelector);

    if ($quantityBoxes && 'date' != $quantityBoxes.prop('type')) {

        // Add plus and minus boxes
        $quantityBoxes.parent().addClass('buttons_added').prepend('<span class="minus quantity-action">-</span>');
        $quantityBoxes.addClass('input-text').after('<span class="plus quantity-action">+</span>');

        // Target quantity inputs on product pages
        jQuery('input' + $quantitySelector + ':not(.product-quantity input' + $quantitySelector + ')').each(function () {
            let $min = parseFloat(jQuery(this).attr('min'));

            if ($min && $min > 0 && parseFloat(jQuery(this).val()) < $min) {
                $(this).val($min);
            }
        });


        jQuery('.plus, .minus').unbind('click');

        jQuery('.plus, .minus').on('click', function () {

                // Get values
                let $quantityBox     = $(this).parent().find($quantitySelector),
                    $currentQuantity = parseFloat($quantityBox.val()),
                    $maxQuantity     = parseFloat($quantityBox.attr('max')),
                    $minQuantity     = parseFloat($quantityBox.attr('min')),
                    $step            = $quantityBox.attr('step');

                // Fallback default values
                if (!$currentQuantity || '' === $currentQuantity || 'NaN' === $currentQuantity) {
                    $currentQuantity = 0;
                }
                if ('' === $maxQuantity || 'NaN' === $maxQuantity) {
                    $maxQuantity = '';
                }

                if ('' === $minQuantity || 'NaN' === $minQuantity) {
                    $minQuantity = 0;
                }
                if ('any' === $step || '' === $step || undefined === $step || 'NaN' === parseFloat($step)) {
                    $step = 1;
                }

                // Change the value
                if ($(this).is('.plus')) {

                    if ($maxQuantity && ( $maxQuantity == $currentQuantity || $currentQuantity > $maxQuantity )) {
                        $quantityBox.val($maxQuantity);
                    } else {
                        $quantityBox.val($currentQuantity + parseFloat($step));
                    }

                } else {

                    if ($minQuantity && ( $minQuantity == $currentQuantity || $currentQuantity < $minQuantity )) {
                        $quantityBox.val($minQuantity);
                    } else if ($currentQuantity > 0) {
                        $quantityBox.val($currentQuantity - parseFloat($step));
                    }

                }

                // Trigger change event
                $quantityBox.trigger('change');
            }
        );
    }
}
jQuery( document ).ready( function( $ ) {
    opalAddQuantityBoxes();
});
jQuery(document).ajaxComplete(function ($) {
    opalAddQuantityBoxes();
});