class OpalThemeBacktop {

    constructor(){
        this.initBacktotop();
    }

    initBacktotop(){
        jQuery(window).scroll(function(){
            if (jQuery(this).scrollTop() > 200) {
                jQuery('.scrollup').fadeIn().addClass('activate');
            } else {
                jQuery('.scrollup').fadeOut().removeClass('activate');
            }
        });
        jQuery('.scrollup').on('click', function(){
            jQuery("html, body").animate({ scrollTop: 0 }, 600);
            return false;
        });
    }

}
new OpalThemeBacktop();