class OpalTheme_Main_Menu {
    constructor() {
        this.initSubmenuHover();

    }

    initSubmenuHover() {
        $('.site-header .main-navigation .menu-item-has-children').hover(event => {
            let $item = $(event.currentTarget);
            this.setPositionLvN($item);
        });

    }

    setPositionLvN($item) {
        let sub          = $item.children('.sub-menu'),
            offset       = $item.offset(),
            width        = $item.outerWidth(),
            screen_width = $(window).width(),
            sub_width    = sub.outerWidth();
        let align_delta = offset.left + width + sub_width - screen_width;
        if (align_delta > 0) {
            sub.css({left: 'auto', right: '100%'});
        }else {
            sub.css({left: '', right: ''});
        }
    }
}

new OpalTheme_Main_Menu();

